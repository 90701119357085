import { useTranslation } from 'react-i18next';
import React, { useMemo } from 'react';
import { differenceInDays } from 'date-fns';

import { RoomAssignmentStatusTableCellProps } from './types';
import { theme } from '@/utils/util_theme';
import { composeClassName } from '@/utils/util_helpers';

import { IconSaxIcon } from '@/components/design-system/atoms/icon';
import { IconSaxIconName } from '@/components/design-system/atoms/icon/iconsax-icon/types';

const RoomAssignmentStatusCellContent: React.FC<RoomAssignmentStatusTableCellProps> =
  ({ status, className, last_update, notice_of_termination_received_at }) => {
    const { t } = useTranslation();

    const iconName: IconSaxIconName = useMemo(() => {
      switch (status) {
        case 'rented':
          return 'check-circle';
        case 'occupied':
          return 'home';
        case 'searching_applicants':
          return 'user-search';
        case 'deposit_paid':
          return 'card-receive';
        case 'awaiting_signature':
          return 'signature';
        case 'awaiting_deposit':
          return 'deposit';
        default:
          return 'calendar-remove';
      }
    }, [status]);

    const textColor: string = useMemo(() => {
      switch (status) {
        case 'searching_applicants':
          return 'text-text-infoHeavy';
        case 'deposit_paid':
        case 'rented':
          return 'text-text-successHeavy';
        case 'awaiting_signature':
        case 'awaiting_deposit':
          return 'text-text-warningHeavy';
        default:
          return 'text-text-primaryHeavy';
      }
    }, [status]);

    const iconStrokeColor: string = useMemo(() => {
      switch (status) {
        case 'searching_applicants':
          return theme?.colors.fill?.icon?.infoHeavy;
        case 'deposit_paid':
          return theme?.colors.fill?.icon?.successHeavy;
        case 'awaiting_signature':
        case 'awaiting_deposit':
          return theme?.colors.fill?.icon?.warningHeavy;
        case 'rented':
          return 'none';
        default:
          return theme?.colors.fill?.icon?.primaryHeavy;
      }
    }, [status]);

    const daysSinceUpdateDate: number = useMemo(() => {
      const today = new Date();
      const timeDiff = differenceInDays(
        last_update ? new Date(last_update) : today,
        today
      );
      return Math.abs(Math.ceil(timeDiff));
    }, [last_update]);

    return (
      <div
        className={composeClassName(
          'flex items-center',
          className && className
        )}
      >
        <IconSaxIcon
          name={iconName}
          strokeWidth={1.2}
          strokeColor={iconStrokeColor}
          size={24}
        />

        <div className="pl-2">
          <span
            className={composeClassName(
              'line-clamp-1 overflow-hidden break-all text-body font-medium',
              textColor
            )}
            style={{ whiteSpace: 'initial' }}
          >
            {t(
              `js.features.admin.contracts.room_assignment.table_cells.room_status.${status}`
            )}
          </span>
          <span
            className="line-clamp-1 overflow-hidden break-all text-body text-text-primaryLight"
            style={{ whiteSpace: 'initial' }}
          >
            {t(
              `js.features.admin.contracts.room_assignment.table_cells.room_status.day_range`,
              { num_days: daysSinceUpdateDate }
            )}
          </span>
        </div>
      </div>
    );
  };

export { RoomAssignmentStatusCellContent };
