export type ContractEventType =
  | 'created'
  | 'accepted_by_admin'
  | 'accepted_by_roommates'
  | 'accepted_by_house_manager'
  | 'application_cancelled_by_coloc'
  | 'application_rejected_by_admin'
  | 'application_rejected_by_notice_cancellation'
  | 'application_rejected_by_availability_date_change'
  | 'application_rejected_by_deadline'
  | 'application_rejected_by_active_contract'
  | 'application_rejected_by_room_rented'
  | 'application_rejected_by_vote'
  | 'application_rejected_by_unknown_reason';

export type RoomAssignmentStatus =
  | 'not_available'
  | 'searching_applicants'
  | 'awaiting_signature'
  | 'awaiting_deposit'
  | 'deposit_paid'
  | 'occupied'
  | 'rented';

export type RoomAssignmentUrgency = 'very_high' | 'high' | 'medium' | 'low';

export type RoomAssignmentUrgencyReason =
  | 'available_now'
  | 'available_soon'
  | 'summer_vacation'
  | 'winter_vacation';

export type RentalType = 'new' | 're-rental' | 'relaunch';

export type RoomApplicantUser = {
  id: string;
  name: string;
  birthday: Date;
  nationality: { country_code: string; country_name: string };
  languages: Array<{ code: string }>;
  work: {
    profession: string;
    company: string;
  };
  phone_number: {
    country_code: string;
    number: string;
  };
  email: string;
  intercom_user_id?: string;
  avatar_url?: string;
};

export type RoomApplicantVotes = {
  accepted: number;
  rejected: number;
  expected_votes: number;
};

export type RoomApplicantChat = { id: number; last_message_stamp: Date };

export const ApplicantReasonContractReason = {
  applied: 'applied',
  accepted_by_admin: 'accepted_by_admin',
  accepted_by_roommates: 'accepted_by_roommates',
  accepted_by_house_manager: 'accepted_by_house_manager',
  accepted_by_queue: 'accepted_by_queue',
  application_cancelled_by_coloc: 'application_cancelled_by_coloc',
  application_rejected_by_admin: 'application_rejected_by_admin',
  application_rejected_by_notice_cancellation:
    'application_rejected_by_notice_cancellation',
  application_rejected_by_availability_date_change:
    'application_rejected_by_availability_date_change',
  application_rejected_by_deadline: 'application_rejected_by_deadline',
  application_rejected_by_active_contract:
    'application_rejected_by_active_contract',
  application_rejected_by_room_rented: 'application_rejected_by_room_rented',
  application_rejected_by_vote: 'application_rejected_by_vote',
  application_rejected_by_unknown_reason:
    'application_rejected_by_unknown_reason',
  queued_by_voting: 'queued_by_voting',
  queued_by_house_manager: 'queued_by_house_manager',
  queued_by_owner: 'queued_by_owner',
  queued_by_admin: 'queued_by_admin',
} as const;

export type ApplicantReasonContractReasonType =
  keyof typeof ApplicantReasonContractReason;

export type ApplicantsContractEvent = {
  created_at: Date;
  action:
    | 'applied'
    | 'queued'
    | 'accepted'
    | 'activated'
    | 'signed'
    | 'cancelled'
    | 'voted'
    | 'changed_tenancy'
    | 'charges_updated'
    | 'rent_indexed';
  reason: string;
  actor: {
    id: string;
    name: string;
    type: string;
  };
};

export type RoomApplicant = {
  user: RoomApplicantUser;
  contract: {
    id: number;
    votes: RoomApplicantVotes;
    status: 'applied' | 'cancelled';
    contract_event: ApplicantsContractEvent;
    move_in_date?: Date;
    motivation: string;
    created_at: Date;
    updated_at: Date;
  };
  chat: RoomApplicantChat;
};

export type ApplicantTenant = {
  id: string;
  name: string;
  nationality: string;
  phone_number: {
    country_code: string;
    number: string;
  };
  email: string;
  contract_id: string;
};

export type RoomAssignmentRoom = {
  id: string;
  room_number: number;
  house: {
    id: string;
    name: string;
  };
};

export type RoomAssignment = {
  id: number;
  discussion_thread: {
    messages_count: number;
    id: string | null;
  };
  urgency: {
    status: RoomAssignmentUrgency;
    reason?: RoomAssignmentUrgencyReason;
  };
  room: RoomAssignmentRoom;
  checkout_tenant?: ApplicantTenant;
  checkin_tenant?: ApplicantTenant;
  current_tenant?: ApplicantTenant;
  assignment_status: {
    status: RoomAssignmentStatus;
    last_update: Date;
  };
  availability_date?: Date;
  end_of_notice_period?: Date;
  notice_of_termination_received_at?: Date;
  applications: Array<RoomApplicant>;
  new_room: boolean;
  rental_type: RentalType;
};

export type PropertyManager = {
  id: number;
  first_name: string;
  last_name: string;
  avatar_url: string;
};
